<template>
  <div
    v-if="stackCouponTip && stackCouponTip?.length"
    class="other-info"
  >
    <div
      v-for="(item, index) in stackCouponTip"
      :key="index"
      class="other-info__body"
    >
      <!-- 叠加规则文案 -->
      <span>
        {{ item.text }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'OverlayRule'
}
</script>
<script setup>
import { computed } from 'vue'
//props
const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})
//解构数据
const stackCouponTip = computed(() => props?.data?.stackCouponTip || [])
</script>

<style lang="less" scoped>
@import './style/index.less';
.other-info {
  margin-top: 0.05rem;
  &__body {
    .flex-config(flex,wrap,center);
    max-width: 6.1333rem;
    align-content: center;
    gap: 0.0933rem;
    align-self: stretch;

    span {
      color: var(--secondary-color);
      .base-font(0.2933rem);
      line-height: normal;
      word-wrap: break-word;
    }
  }
}
</style>
