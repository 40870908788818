var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.categoryLabelList.length)?_c('div',{ref:el => (_setup.refs[_setup.id] = el),class:['coupon__tabs', _setup.isMulti && 'more_mall']},[_c('ul',{ref:"textContainer",staticClass:"tags-list",attrs:{"id":`coupon-desc-content-${_setup.id}`}},[_vm._l((_setup.categoryLabelList.slice(
        0,
        _setup.lastDisplayedIndex + 1
      )),function(tag,index){return _c('li',{key:index,ref:"tagItems",refInFor:true,staticClass:"coupon-categories__item"},[_vm._v("\n      "+_vm._s(tag)+"\n    ")])}),_vm._v(" "),(
        _setup.isExpanded ||
          (!_setup.isExpanded && _setup.lastDisplayedIndex < _setup.categoryLabelList?.length - 1)
      )?_c('li',{staticClass:"toggle-btn-open",on:{"click":function($event){$event.stopPropagation();return (() => _setup.onToggleBtn()).apply(null, arguments)}}},[_c(_setup.Icon,{directives:[{name:"show",rawName:"v-show",value:(!_setup.isExpanded),expression:"!isExpanded"}],attrs:{"name":"sui_icon_more_down_16px","size":"0.32rem","color":'var(--secondary-color)'}}),_vm._v(" "),_c(_setup.Icon,{directives:[{name:"show",rawName:"v-show",value:(_setup.isExpanded),expression:"isExpanded"}],attrs:{"name":"sui_icon_more_up_12px_2","color":'var(--secondary-color)',"size":"0.32rem"}})],1):_vm._e()],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }