/** 
 * apply_for: 1 全部商品
 * apply_for: 2 部分商品
 * apply_for: 3 部分品类
 * apply_for: 4 废弃，产品反馈不存在了
 * apply_for: 5 减最低邮费
 * apply_for: 6 免邮卷
 * apply_for: 7 选品页面
 * apply_for: 8 部分店铺的平台券
 * apply_for: 9 指定运输方式减运费
 * apply_for: 10 会费折扣券类型
*/

// 每种券由哪些字段控制判断
exports.COUPON_REAL_TYPE = {
  freeShippingCoupon: {
    applyFor: ['5', '6', '9'],  // apply_for 字段的取值为5,6,9
  },
  clubCoupon: {
    realTypeId: ['53', '58'] // SHEIN CLUB优惠券（typeid=53）SHEIN CLUB会费抵扣券（typeID=58）
  },
  saverCoupon: {
    realTypeId: ['55', '59'] // SHEIN SAVER优惠券（typeid=55）SHEIN SAVER会费抵扣券（typeID=59）
  },
  expiredCoupon: {
    timeStatus: '3'
  },
  memberShipCoupon: {
    realTypeId: ['58', '59'] // 会费折扣券
  }
}

exports.COUPON_APPLY_FOR = {
  // 全部商品
  AllProducts: '1',
  // 部分商品
  SomeProducts: '2',
  // 部分品类
  SomeCategories: '3',
  // 减最低邮费
  MinimumPostageReduced: '5',
  // 免邮券
  FreeShipping: '6',
  // 选品页面
  ProductSelectionPage: '7',
  // 部分店铺的平台券
  SomeStoresFromPlatform: '8',
  // 指定运输方式减运费
  MinusFreightOnSpecificTransport: '9',
  // 会费券
  ClubCoupon: '10',
}

exports.COUPON_BUSINESS_TYPE = {
  categoryCoupon: 'category_coupon',
  shippingCoupon: 'shipping_coupon',
  storeCoupon: 'store_coupon',
  newUserCoupon: 'new_user_coupon',
  specialCoupon: 'special_coupon'
}

exports.COUPON_COLOR_TYPE = {
  normalCoupon: 'normal_coupon',
  shippingCoupon: 'shipping_coupon',
  clubCoupon: 'club_coupon',
  saverCoupon: 'saver_coupon',
  expiredCoupon: 'expired_coupon'
}

exports.COUPON_SHEIN_TYPE = {
  saverCoupon: 'shein_saver',
  clubCoupon: 'shein_club',
}

exports.COUPON_CATEGORY_TYPE = {
  primeCoupon: 'prime', // 会员专享券
  saverCoupon: 'save_card', // 超省卡
  primeRightCoupon: 'prime_right', // 付费会员-会费券
  saveCardRightCoupon: 'save_card_right', // 超省卡-会费券
  oldOrderReturnCoupon: 'old_order_return', // 老客订单返券专属类型
  addCoupon: 'add_coupon', // 手动输入券(下单页券列表)
}

exports.COUPON_STATUS_TYPE = {
  unused: 1, // 未使用
  used: 3, // 已使用
  expired: 4, // 已过期
}

exports.WATER_MARK_TYPE = {
  unshow: 0, // 不展示
  isExpired: 1, // 已过期
  isClaimedAll: 2, // 已领完
  isExpanded: 3, // 已膨胀
  isClaimed: 4, // 已领取
  isUsed: 5, // 已使用
  isInvalid: 6, // 已作废
}

// 券面的交互按钮类型
exports.COUPON_LAYER_BUTTON_TYPE = {
  unshow: '0', // 不展示
  shop: '1', // 去购物
  claim: '2', // 领取
  availableAndUnselected: '3', // 未选中筛选框
  availableAndSelected: '4', // 已选中筛选框
  disabledAndUnselected: '5', // 未选中 & 置灰筛选框
  disabledAndSelected: '6', // 已选中 & 置灰筛选框
}

exports.SAVE_MONEY = {
  unshow: 0, // 不展示
  show: 1, // 展示
}

exports.NEW_TIPS = {
  unshow: 0, // 不展示
  show: 1, // 展示
}

// 为0时，不展示
// 为1时，券面存在最佳优惠提示
// 为2时，券面存在可叠加标签
exports.INFORM_TYPE = {
  unshow: 0, // 不展示
  bestChioce: 1, // 最佳优惠
  stackable: 2, // 可叠加
}

// 运费券叠加规则
exports.STACKABLE_RULE = {
  unshow: 0, // 不展示
  show: 1, // 展示
}

// 组件接入方
exports.ACTIVITY_FROM = {
  meCouponComponent: 'me_coupon_Component', // 个人中心券列表
  cart: 'cart_Component', // 购物车券列表
  order: 'order_Component', // 下单页券列表
}

/**
 * @description 券适用店铺类型
 * @readonly
 * @enum {number}
 * @property {number} SELF 自营 - 1
 * @property {number} TRIPARTITE 三方 - 2
 * @property {number} SELF_AND_TRIPARTITE 自营和三方 - 3
 * @property {number} SELF_AND_TRIPARTITE 自营品类 - 4
 */
exports.PLATFORM_STORE_TYPE = {
  UNKNOW: -1,
  SELF: 1,
  TRIPARTITE: 2,
  SELF_AND_TRIPARTITE: 3,
  SELF_CATEGORY: 4,
}

/**
 * @description 券免邮适用场景
 * @readonly
 * @enum {number}
 * @property {number} ALL 全部商品 - 1
 * @property {number} PART 部分店铺 - 8
 */
exports.SHIPPING_APPLY_FOR = {
  UNKNOW: -1,
  ALL: 1,
  PART: 8
}
