// 券面颜色
export const COUPON_COLOR = {
  // 普通券
  normalCoupon: { 
    coreColor: '#F6451E', // 券核心色(面额/省额/行动按钮/用券引导标签等主要元素)
    secondaryColor: '#A63E00', // 券辅助色(券类型/订单门槛/叠加规则/使用次数等次要信息)
    bgColor: '#FFF6F3', // 背景色
    borderColor: '#FFE2CF', // 券描边色
    btnColor: '#000000', // 交互按钮颜色
    maskColor: 'rgba(255, 246, 243, 0.5)', // 蒙层色(当券不可用/已使用时的蒙层色) bgColor+透明度
    countDownBgColor: 'rgba(246, 69, 30, .13)', // 倒计时背景颜色(核心色+13%透明度)
    supplementaryInfoTextColor: '#767676', // 辅助信息文案颜色
    leftTopTagColor: 'rgba(246, 69, 30, .08)', // 左上角标签颜色
  },
  // 邮费券
  freeShippingCoupon: { 
    coreColor: '#169E00', 
    secondaryColor: '#34622B', 
    bgColor: '#F3FAF6', 
    borderColor: '#CBE2C7', 
    btnColor: '#000000',
    maskColor: 'rgba(243, 250, 246, 0.5)',
    countDownBgColor: 'rgba(22, 158, 0, .13)',
    supplementaryInfoTextColor: '#767676',
    leftTopTagColor: 'rgba(22, 158, 0, .08)',
  },
  // 会员券
  clubCoupon: { 
    coreColor: '#CD5517', 
    secondaryColor: '#873C00', 
    bgColor: '#FFF7EF', 
    borderColor: '#FBE1D4', 
    btnColor: '#000000',
    maskColor: 'rgba(255, 247, 239, 0.5)',
    countDownBgColor: 'rgba(205, 85, 23, .13)',
    supplementaryInfoTextColor: '#767676',
    leftTopTagColor: 'rgba(205, 85, 23, .06)',
  },
  // 超省券
  saverCoupon: { 
    coreColor: '#F82854', 
    secondaryColor: '#AF1939', 
    bgColor: '#FFF4F7', 
    borderColor: '#FED9E0', 
    btnColor: '#000000',
    maskColor: 'rgba(255, 244, 247, 0.5)',
    countDownBgColor: 'rgba(248, 40, 84, .13)',
    supplementaryInfoTextColor: '#767676',
    leftTopTagColor: 'rgba(248, 40, 84, .06)',
  },
  // 过期券
  expiredCoupon: {
    coreColor: '#959595', 
    secondaryColor: '#959595', 
    bgColor: '#F6F6F6', 
    btnColor: '#000000',
    borderColor: '#E5E5E5', 
    countDownBgColor: 'rgba(149, 149, 149, 0.13)',
    supplementaryInfoTextColor: '#959595',
    leftTopTagColor: 'rgba(149, 149, 149, 0.1)',
  }
}

// 附加组件样式 https://wiki.dotfashion.cn/pages/viewpage.action?pageId=1311682351
export const ADDITIONAL_COMPONENT_STYLE = {
  unShow: '0', // 不展示
  firstStyle: '1', // 第一种样式
  secondStyle: '2', // 第二种样式
  thirdStyle: '3', // 第三种样式
  fourthStyle: '4', // 第四种样式
  fifthStyle: '5', // 第五种样式
  sixthStyle: '6', // 第六种样式
  seventhStyle: '7', // 第七种样式
  eighthStyle: '8', // 第八种样式
  ninthStyle: '9', // 第九种样式
}

// 辅助信息状态
export const ASSIST_INFORMATION_TYPE = {
  fold: 1, // 折叠
  unfold: 2, // 展开
}

// 券面点击事件触发的 button 类型
export const COUPON_LAYER_BUTTON_CLICK_TYPE = {
  use: '1', // 使用
  claim: '2', // 领取
  availableAndUnselected: '3', // 未选中筛选框
  availableAndSelected: '4', // 已选中筛选框
  disabledAndUnselected: '5', // 未选中 & 置灰筛选框
  disabledAndSelected: '6', // 已选中 & 置灰筛选框
}

// 附加组件点击事件触发的 button 类型
export const ADDITIONAL_COMPONENT_BUTTON_CLICK_TYPE = {
  add: '1', // 加购
  view: '2', // 查看更多
}

// 点击事件来源
export const CLICK_EVENT_FROM = {
  couponLayer: 'couponLayer', // 券面
  addModule: 'addModule', // 附加组件
  couponHelp: 'couponHelp', // 帮助中心
  couponChecked: 'couponChecked', // 选中券
  couponAssistInformationType: 'couponAssistInformationType', // 辅助信息展开/折叠
}

export const SAVE_MONEY = {
  unshow: 0, // 不展示
  show: 1, // 展示
}

export const NEW_TIPS = {
  unshow: 0, // 不展示
  show: 1, // 展示
}

// 为0时，不展示
// 为1时，券面存在最佳优惠提示
// 为2时，券面存在可叠加标签
export const INFORM_TYPE = {
  unshow: 0, // 不展示
  bestChioce: 1, // 最佳优惠
  stackable: 2, // 可叠加
}

// 运费券叠加规则
export const STACKABLE_RULE = {
  unshow: 0, // 不展示
  show: 1, // 展示
}

// 辅助信息状态
export const ASSIST_INFORMATION_LIST = {
  application: 1, // 适用范围
  code: 2, //券码
  rightProduct: 3, //会员附属信息 - 产品包
  pay: 4, // 支付限制
  shippintMethods: 5, // 运输限制
  otherOptions: 6, // 梯度说明
  postAmount: 7, // 法务文案
}

// 组件接入方
export const ACTIVITY_FROM = {
  meCouponComponent: 'me_coupon_Component', // 个人中心券列表
  cart: 'cart_Component', // 购物车券列表
  order: 'order_Component', // 下单页券列表
}
