<template>
  <!-- 多档用more_mall，单档去除该class -->
  <div
    v-if="categoryLabelList.length"
    :ref="el => (refs[id] = el)"
    :class="['coupon__tabs', isMulti && 'more_mall']"
  >
    <ul
      :id="`coupon-desc-content-${id}`"
      ref="textContainer"
      class="tags-list"
    >
      <li
        v-for="(tag, index) in categoryLabelList.slice(
          0,
          lastDisplayedIndex + 1
        )"
        :key="index"
        ref="tagItems"
        class="coupon-categories__item"
      >
        {{ tag }}
      </li>
      <li
        v-if="
          isExpanded ||
            (!isExpanded && lastDisplayedIndex < categoryLabelList?.length - 1)
        "
        class="toggle-btn-open"
        @click.stop="() => onToggleBtn()"
      >
        <Icon
          v-show="!isExpanded"
          name="sui_icon_more_down_16px"
          size="0.32rem"
          :color="'var(--secondary-color)'"
        />
        <Icon
          v-show="isExpanded"
          name="sui_icon_more_up_12px_2"
          :color="'var(--secondary-color)'"
          size="0.32rem"
        />
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'RuleTags'
}
</script>
<script setup>
import { ref, defineProps, onMounted, reactive, computed, nextTick } from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'
import { debounce } from 'lodash'
let isExpanded = ref(false)
let refs = reactive({})
let isMulti = ref(false)
let tagItems = ref([])
let textContainer = ref(null)
// 折叠后最后一个显示的标签 index（不包括折叠标签）
let lastDisplayedIndex = ref(999)
let isResizeHandling = ref(false)
let windowWidth = ref(window.innerWidth)
// 手动折叠展开
let toggle = ref(false)
let lastHeight = ref(null)

//接收props
const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})
//解构
const id = computed(() => props?.data?.id)
const categoryLabelList = computed(() => props?.data?.categoryLabelList || [])
const rules = computed(() => props?.data?.rules || [])
// let { categoryLabelList = [], rules = [], id = [] } = toRefs(originDataReactive)

onMounted(() => {
  isMulti.value = rules.value?.length > 1
  lastDisplayedIndex.value = categoryLabelList.value?.length - 1
  bindEventListener()
  nextTick(() => {
    bindResizeObserver()
  })
})

// 展开收起
const onToggleBtn = () => {
  toggle.value = true
  isExpanded.value = !isExpanded.value
  // let keyId = id.value || ''
  if (isExpanded.value) {
    lastDisplayedIndex.value = categoryLabelList.value?.length - 1
    // refs[keyId].style.maxHeight = 'none'
  } else {
    handleCollapsedResize()
    // refs[keyId].style.maxHeight = '19px'
  }
}

const bindEventListener = () => {
  const handleWindowResize = debounce(() => {
    const newWidth = window.innerWidth
    if (newWidth > windowWidth.value) {
      // 浏览器窗口放大时，标签位置有变化，但没有触发容器尺寸变化，需要手动处理一下
      lastDisplayedIndex.value = categoryLabelList.value?.length - 1
      handleCollapsedResize()
    }
    windowWidth.value = newWidth
  }, 200)
  window.addEventListener('resize', handleWindowResize)
}
const bindResizeObserver = () => {
  const resizeObserver = new ResizeObserver(entries => {
    for (let entry of entries) {
      const currentHeight = entry.contentRect.height
      // 手动折叠展开引发的尺寸变更
      if (toggle.value) {
        toggle.value = false
        // 更新尺寸
        lastHeight.value = currentHeight
        return
      }
      // 折叠时，位置不够导致的换行
      if (currentHeight > lastHeight.value) handleCollapsedResize()
      // 更新尺寸
      lastHeight.value = currentHeight
    }
  })
  textContainer.value && resizeObserver.observe(textContainer.value)
}
const handleCollapsedResize = rehandle => {
  if (!textContainer.value) return
  const couponCategoriesRef = textContainer.value
  if (!couponCategoriesRef || !couponCategoriesRef.children.length) return
  // 如果非折叠状态，不需要处理
  if (isResizeHandling.value) return
  isResizeHandling.value = true
  const rowHeight = couponCategoriesRef.children[0].clientHeight
  const marginTop = couponCategoriesRef.children[0].offsetTop
  if (couponCategoriesRef.offsetHeight > rowHeight) {
    // 包含折叠标签
    const displayedChildren = [...couponCategoriesRef.children].slice(
      0,
      lastDisplayedIndex.value + 2
    )
    const index = displayedChildren.findIndex(
      child => child.offsetTop > marginTop
    )
    if (index === 0) {
      // 第一个标签长于一行时
      lastDisplayedIndex.value = index
    } else if (index >= displayedChildren.length - 1 && isExpanded.value) {
      // 元素都没有换行，且展开状态，即折叠标签换行了
      isExpanded.value = false
      lastDisplayedIndex.value = categoryLabelList.value?.length - 1
    } else if (index > 0 && !isExpanded.value) {
      lastDisplayedIndex.value = rehandle
        ? lastDisplayedIndex.value - 1
        : index - 1
      isResizeHandling.value = false
      // 折叠标签因不够位置而换行时需要重新计算，处理一次就够了
      if (!rehandle)
        nextTick(() => {
          handleCollapsedResize(true)
        })
    }
    isResizeHandling.value = false
  }
  isResizeHandling.value = false
}
</script>

<style lang="less" scoped>
@import './style/index.less';
.coupon__tabs {
  .flex-config(flex,nowrap,null);
  // padding: 0 0.2667rem;
  max-width: 100%;
  margin-top: 0.1067rem;
  overflow: hidden;
  box-sizing: content-box;
  .tags-list {
    .flex-config(flex,wrap,null);
    gap: 0.1067rem;
    .coupon-categories__item {
      max-width: none;
      padding: 0 0.1067rem;
      border-radius: 1px;
      border: 0.5px solid var(--border-color);
      color: var(--secondary-color);
      .base-font(0.2667rem);
      height: 0.4rem;
      line-height: 0.38rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .toggle-btn-open {
      // width: 15px;
      text-align: center;
      .expand-btn();
    }
  }
  .toggle-btn-close {
    margin-left: 0.1067rem;
    .expand-btn();
    //  width: 15px;
  }
}
.more_mall {
  max-width: none;
  padding: 0 0.2667rem;
}
</style>
