<template>
  <div
    v-if="coupon_amount && times && times > 1"
    class="coupon-count"
  >
    X{{ num }}
  </div>
</template>
<script>
export default {
  name: 'CouponCount'
}
</script>
<script setup>
import { inject, computed } from 'vue'

let originData = inject('couponDataInfo')
//解构数据
let { times = '', coupon_amount = 1 } = originData || {}
const num =
  computed(() =>
    originData?.times && originData?.times > 999 ? '999+' : originData?.times
  ) || ''
</script>

<style lang="less" scoped>
.coupon-count {
  background-color: var(--countdown-bg-color);
  color: var(--secondary-color);
  padding: 0px 0.1333rem 0 0.16rem;
  // line-height: 0.2933rem;
  height: 0.3467rem;
  line-height: 0.3467rem;
  font-family: 'SF UI Text', sans-serif;
  font-size: 0.2667rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
  border-radius: 0px 3.5px 0px 4px;
  float: right;
}
</style>
