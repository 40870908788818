<template>
  <!-- 多档劵 -->
  <div :class="['core-info__multi-mall', otherInfo ? 'space' : '']">
    <div
      v-for="(item, index) in rules"
      :key="index"
      class="multi-mall-box"
    >
      <h2
        ref="title"
        class="core-info__title"
      >
        {{ getDiscount(item?.discount) }}
        <!-- 若coupon_gift_id字段存在数值，则在对应档位金额区域增加“&free gift”文案 -->
        <span
          v-if="item?.discountExtra"
          class="gift"
        >
          {{ item?.discountExtra }}
        </span>
      </h2>
      <!-- 相关信息 -->
      <div class="apply-orders">
        <p class="core-info__text">
          {{ item?.threshold }}
        </p>
      </div>
    </div>
    <CouponInfo
      :is-multi="true"
      :data="data"
    />
  </div>
</template>
<script>
export default {
  name: 'MultiCoupon'
}
</script>
<script setup>
import { computed } from 'vue'
import CouponInfo from './CouponInfo'
//props
const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})
const { GB_cssRight } = gbCommonInfo

const rules = computed(() => props.data?.rules)

const otherInfo = computed(
  () => props.data?.stackCouponTip?.length > 0 || props.data?.categoryLabelList?.length > 0
)

const getDiscount = (discount) => {
  const res = GB_cssRight ? discount.replace(/(-?)(\d+(\.\d+)?)(%)/, '$4$2$1') :  discount
  return res || ''
}
</script>

<style lang="less" scoped>
.base-font(@size-factor) {
  font-family: 'SF UI Text', sans-serif;
  font-weight: 400;
  font-size: @size-factor;
}
.core-info {
  &__title {
    display: inline-flex;
    align-items: baseline;
    gap: 0.053rem 0.1067rem;
    align-self: stretch;
    flex-wrap: wrap;
    max-width: 100%;
    color: var(--core-color);
    word-wrap: break-word;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol';
    font-weight: 700;
    font-size: 0.6933rem;
    line-height: 0.6933rem;
    margin-bottom: 0.1067rem;
    .gift {
      font-size: 0.42667rem;
      text-transform: none;
    }
  }
  &__multi-mall {
    display: flex;
    align-items: flex-start;
    gap: 0.32rem;
    flex-direction: column;
    .core-info__text {
      color: var(--secondary-color);
      .base-font(0.2933rem);
      line-height: normal;
      word-wrap: break-word;
    }
  }
  .space {
    margin-bottom: 0.32rem;
  }
}
</style>
